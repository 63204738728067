<template>
  <l-content-widget
    fullHeight
  >
    <template #widget>
      <l-widget
        fixed-height
        no-ident
      >
        <template #header>
          <div class="p-8 p-xl-16">
            <w-timer
              :start="widget.timer.start"
              :end="widget.timer.end"
              :server-time="widget.timer.current"
            />
          </div>
        </template>

        <template #preContent>
          <div class="d-none d-xl-block">
            <div class="p-16">
              <w-progress
                :progress="widget.progress"
                class="mb-8"
              />
            </div>

            <v-divider class="mb-16"/>

            <p class="text-bold text-color-primary mb-8 px-16">Список вопросов</p>
          </div>
        </template>

        <template #default>
          <div class="px-16 pt-0 d-none d-xl-block">
            <ul class="ul">
              <li
                v-for="link in widget.links"
                :key="link.id"
              >
                <div class="row align-items-center mb-4">
                  <div class="col text-bold">
                    <a
                      class="link-standard text-size-12"
                      href="/"
                    >
                      {{ link.text }}
                    </a>
                  </div>
                  <div class="col-auto">
                    <v-avatar
                      size="8"
                      :color="link.color"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <v-select
            :value="widget.currentLink"
            :options="widget.links"
            :clearable="false"
            :filterable="false"
            :searchable="false"
            label="text"
            @input="changeSelect"
            class="d-xl-none v-select_type_widget"
          >
            <template #option="{ text, color }">
              <div class="row">
                <div class="col">{{ text }}</div>
                <div class="col-auto">
                  <v-avatar
                    size="8"
                    :color="color"
                  />
                </div>
              </div>

            </template>
          </v-select>
        </template>

        <template #footer>
          <w-progress
            :progress="widget.progress"
            class="p-8 d-xl-none"
          />
        </template>
      </l-widget>
    </template>

    <template #default>
      <v-sheet
        rounded="lg"
        elevation="4"
        class="p-16"
      >
        <div class="row">
          <div class="col">
            Карл у Клары украл

            <v-field>
              <v-input
                v-model="input1.value"
                :placeholder="input1.placeholder"
                autogrow
              />
            </v-field>

            , а Клара у Карла украла

            <v-field>
              <v-input
                v-model="input2.value"
                :placeholder="input2.placeholder"
                autogrow
              />
            </v-field>

            . Если бы Карл у Клары не украл

            <v-select
              v-model="input3.value"
              :clearable="false"
              :filterable="false"
              :searchable="false"
              :options="options"
              :placeholder="input3.placeholder"
              class="v-select_is_inline"
            />

            , Клара у Карла не украла б

            <v-select
              v-model="input4.value"
              :clearable="false"
              :filterable="false"
              :searchable="false"
              :options="options"
              :placeholder="input4.placeholder"
              class="v-select_is_inline"
            />
          </div>
        </div>

        <div class="row mt-40">
          <div class="col-auto">Ответ 1: {{ input1.value }}</div>
          <div class="col-auto">Ответ 2: {{ input2.value }}</div>
          <div class="col-auto">Ответ 3: {{ input3.value }}</div>
          <div class="col-auto">Ответ 4: {{ input4.value }}</div>
        </div>

        <div class="row mt-16">
          <div class="col">
            <v-button @click="onClick">
              Ответить
            </v-button>
          </div>
        </div>
      </v-sheet>

    </template>
  </l-content-widget>

</template>

<script>
import VSelect from 'vue-select'
import { LContentWidget, LWidget } from '@/layouts'
import { VAvatar, VButton, VDivider, VSheet, VField, VInput } from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { createCustomArray, getRandomInt } from '@utils/helpers'

const bulletColors = ['default', 'primary', 'warning']

export default {
  name: 'SNlmkFillingInThePass',

  components: {
    VDivider,
    VAvatar,
    WProgress,
    WTimer,
    VSheet,
    LContentWidget,
    LWidget,
    VButton,
    VField,
    VInput,
    VSelect
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 60 * 20
        },
        links: createCustomArray({
          length: 20,
          template: i => ({
            id: i,
            text: `Вопрос ${i}`,
            color: bulletColors[getRandomInt(0, 2)]
          })
        }),
        currentLink: 'Вопрос 1'
      },
      answer: '',
      input1: { value: '', placeholder: 'введите' },
      input2: { value: '', placeholder: 'введите' },
      input3: { value: '', placeholder: 'введите' },
      input4: { value: '', placeholder: 'введите' },
      options: ['кораллы', 'билет', 'кларнет', 'кристаллы']
    }
  },

  methods: {
    onClick () {
      this.answer = `
      1: ${this.input1.value}
      2: ${this.input2.value}
      3: ${this.input3.value}
      4: ${this.input4.value}
      `
    },

    changeSelect (currentLink) {
      this.widget.currentLink = currentLink

      // this.$nextTick(() => {
      //   window.location.hash = `#${selectedAnchor.link}`
      // })
    }
  }
}
</script>
